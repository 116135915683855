



















































import SupplierPaymentCategoryApi from "@/modules/supplier/api/supplier-payment-category.api";
import { Component, Prop, Vue } from "vue-property-decorator";
import { SupplierPaymentCategory } from "@/modules/supplier/types/supplier-payment-category.type";
import ErrorMessage from "@/components/layout/ErrorMessage.vue";
import { Getter } from "vuex-class";
import { Pinkas } from "@/modules/pinkas/types/pinkas.type";

@Component({
  components: {
    ErrorMessage,
  }
})
export default class SupplierPaymentCategoryForm extends Vue {
  @Prop({ required: false }) public editSupplierPaymentCategory: SupplierPaymentCategory;
  @Getter("auth/pinkasList") pinkasList: Pinkas[];
  @Getter("auth/userDefaultPinkasId") userDefaultPinkasId: number;

  public errors: any = false;
  public pinkasId: number = null;
  public name = "";
  public maamRate: number = null;
  public taxRecognizedRate = 100;
  public isLoading = false;
  public deleteLoading = false;

  mounted() {
    if (this.editSupplierPaymentCategory) {
      this.name = this.editSupplierPaymentCategory.name;
      this.pinkasId = this.editSupplierPaymentCategory.pinkas_id;
      this.maamRate = this.editSupplierPaymentCategory.maam_rate * 100;
      this.taxRecognizedRate = this.editSupplierPaymentCategory.tax_recognized_rate * 100;
    } else {
      this.pinkasId = this.userDefaultPinkasId ? this.userDefaultPinkasId : this.pinkasList[0].id;
      this.maamRate = this.$store.getters["auth/maamRate"] * 100;
    }
  }

  public closeDialog() {
    this.$emit("close");
  }

  public async submitForm() {
    if (this.isLoading) { return; }

    this.isLoading = true;
    this.errors = false;

    const payload = {
      pinkas_id: this.pinkasId,
      name: this.name,
      maam_rate: this.maamRate / 100,
      tax_recognized_rate: this.taxRecognizedRate / 100,
    };

    const attempt = this.editSupplierPaymentCategory
      ? SupplierPaymentCategoryApi.update(this.editSupplierPaymentCategory.id, payload)
      : SupplierPaymentCategoryApi.create(payload);

    attempt
      .then(() => {
        this.isLoading = false;

        this.$toastr.s(this.editSupplierPaymentCategory ? this.$t("supplier_payment_category_updated") : this.$t("supplier_payment_category_created"));
        this.closeDialog();
        this.$emit("reload");
      })
      .catch(err => {
        this.isLoading = false;
        if (err.response && err.response.data && err.response.data.errors) {
          this.errors = err.response.data.errors;
        } else {
          this.errors = this.$t("temporary_error");
        }
      });
  }

  public async onDeleteClicked() {
    const res = await this.$confirm(this.$t("delete_confirm_message").toString(), {
      buttonTrueColor: "red",
      buttonTrueText: this.$t("delete_ok_btn").toString(),
      buttonFalseText: this.$t("delete_cancel_btn").toString()
    });

    if (!res) {
      return;
    }

    try {
      this.deleteLoading = true;
      await SupplierPaymentCategoryApi.delete(this.editSupplierPaymentCategory.id);
      this.$toastr.s(this.$t("deleted_successfully"));
      this.$emit("reload");
      this.closeDialog();
    } catch (err) {
      this.$toastr.e(this.$t("delete_error"));
      this.$emit("reload");
    }
  }

}
