






































































































































import UserApi from "@/modules/users/api/user.api";
import { Component, Vue } from "vue-property-decorator";
import ErrorMessage from "@/components/layout/ErrorMessage.vue";
import { OPTIONAL_LANGUAGES } from "@/modules/auth/constants/language-options";
import GoogleContactsSyncConnect from "@/modules/google-contacts-sync/components/GoogleContactsSyncConnect.vue";
import moment from "moment";
import { User } from "@/modules/users/types/user.type";
import { Getter } from "vuex-class";
import { Pinkas } from "@/modules/pinkas/types/pinkas.type";

@Component({
  components: {
    ErrorMessage,
    GoogleContactsSyncConnect,
  },
})
export default class ProfilePage extends Vue {
  @Getter("auth/isTherapist") isTherapist: boolean;
  @Getter("auth/pinkasList") pinkasList: Pinkas[];

  public newPasswordShow = false;
  public name = "";
  public email = "";
  public phone = "";
  public locale = "";
  public birthDate = "";
  public currentPassword = "";
  public newPassword = "";
  public isWaLinkDirect = false;
  public defaultLetterHeader = "";
  public isShowingFullTreatmentNotes = false;
  public defaultLetterSignature = "";
  public defaultPinkasId: number = null;
  public errors: any = false;
  public loading = false;

  mounted() {
    this.name = this.$store.getters["auth/user"].name;
    this.email = this.$store.getters["auth/user"].email;
    this.phone = this.$store.getters["auth/user"].phone;
    this.isWaLinkDirect = this.$store.getters["auth/isWaLinkDirect"];
    this.birthDate = this.$store.getters["auth/user"].birth_date ? moment(this.$store.getters["auth/user"].birth_date).format("DD/MM/YYYY") : null;
    this.defaultLetterHeader = this.$store.getters["auth/defaultLetterHeader"];
    this.defaultLetterSignature = this.$store.getters["auth/defaultLetterSignature"];
    this.locale = this.$store.getters["auth/user"].locale;
    this.defaultPinkasId = this.$store.getters["auth/userDefaultPinkasId"];
    this.isShowingFullTreatmentNotes = this.$store.getters["auth/isShowingFullTreatmentNotes"];
  }

  get localeOptions() {
    return OPTIONAL_LANGUAGES.map(lang => ({
      value: lang,
      text: this.$t("locale_options." + lang)
    }));
  }

  public updateProfile() {
    this.loading = true;
    this.errors = false;

    const payload = {
      ...(this.$store.getters["auth/user"] as User),
      name: this.name,
      email: this.email,
      phone: this.phone,
      locale: this.locale,
      current_password: this.currentPassword,
      is_wa_link_direct: this.isWaLinkDirect,
      birth_date: this.birthDate ? moment(this.birthDate, "DD/MM/YYYY").format("YYYY-MM-DD") : null,
      new_password: this.newPassword && this.newPassword.length ? this.newPassword : undefined,
      color: this.$store.getters["auth/userColor"],
      default_pinkas_id: this.defaultPinkasId,
      default_letter_header: this.defaultLetterHeader,
      default_letter_signature: this.defaultLetterSignature,
      is_showing_full_treatment_notes: this.isShowingFullTreatmentNotes
    };

    UserApi.update(this.$store.getters["auth/user"].id, payload)
      .then(user => {
        this.loading = false;
        this.$toastr.s(this.$t("success_toastr"));
        this.newPassword = "";
        this.currentPassword = "";

        this.$store.commit("auth/setUser", user);

        this.$vuetify.rtl = this.$store.getters["auth/isRtl"];
        this.$i18n.locale = this.$store.getters["auth/locale"];
        this.$root.$i18n.locale = this.$store.getters["auth/locale"];
        this.$vuetify.lang.current = this.$store.getters["auth/locale"];
      })
      .catch(err => {
        this.loading = false;
        if (err.response && err.response.data && err.response.data.errors) {
          this.errors = err.response.data.errors;
        } else {
          this.errors = this.$t("temporary_error");
        }
      });
  }

  // get addGoogleCalendarLink() {
  //   // https://stackoverflow.com/questions/71595047/google-url-to-subscribe-to-a-calendar
  //   return "https://calendar.google.com/calendar/u/0/r?cid=" + (this.currentUserCalendarUrl.replace("https", "webcal"));
  // }
}
