











































































































































import { Component, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { Patient } from "@/modules/patient/types/patient.type";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import { age, date, time } from "@/filters/date.filter";
import PatientTreatmentFormDialog from "./PatientTreatmentFormDialog.vue";
import { PatientTreatment } from "../types/patient-treatment.type";
import { Treatment } from "@/modules/treatment/types/treatment.type";
import { Task, TaskRecurring } from "@/modules/task/types/task.type";
import TaskFormDialog from "@/modules/task/components/TaskFormDialog.vue";
import { PatientFile } from "@/modules/patient-file/types/patient-file.type";
import ShowPatientFileDialog from "@/modules/patient-file/components/patient-file/ShowPatientFileDialog.vue";
import LabWorkFormDialog from "@/modules/lab-work/components/LabWorkFormDialog.vue";
import { LabWork } from "@/modules/lab-work/types/lab-work.type";
import moment from "moment";

@Component({
  components: {
    PatientTreatmentFormDialog,
    TaskFormDialog,
    ShowPatientFileDialog,
    LabWorkFormDialog
  },
  filters: {
    age,
    date,
    time
  }
})
export default class PatientTreatments extends mixins(SmileMixin) {
  @Prop({ required: true }) public patient: Patient;
  @Getter("auth/isRtl") isRtl: boolean;
  @Getter("auth/shouldDisplayTodoTasks") shouldDisplayTodoTasks: boolean;
  @Getter("auth/isShowingPatientTreatmentPrice") isShowingPatientTreatmentPrice: boolean;
  @Getter("auth/isShowingFullTreatmentNotes") isShowingFullTreatmentNotes: boolean;

  public displayTaskFormDialog = false;
  public editingTask: Task = null;
  public displayTreatmentFormDialog = false;
  public editingTreatment: PatientTreatment = null;
  public showMoreIds: number[] = [];
  public showPatientFile: PatientFile = null;
  public labWork: LabWork = null;

  public onEditTreatmentClicked(treatment: PatientTreatment) {
    this.displayTreatmentFormDialog = true;
    this.editingTreatment = treatment;
  }

  public showTime(treatment) {
    return (treatment.itemType === "labWork" && moment(treatment.created_at).format("HH:mm") !== "00:00") || treatment.itemType !== "labWork";
  }

  public onEditTaskClicked(task: Task) {
    this.displayTaskFormDialog = true;
    this.editingTask = task;
  }

  public onEditLabWorkClicked(selectedLabWork: LabWork) {
    this.labWork = selectedLabWork;
  }

  get patientTreatments() {
    let paymentTreatment = this.$store.getters["auth/treatments"]
      .find((treatment: Treatment) => (treatment.name === "תשלום" && !treatment.deleted_at));

    if (!paymentTreatment) {
      paymentTreatment = this.$store.getters["auth/treatments"]
        .find((treatment: Treatment) => (treatment.name === "תשלום"));
    }

    const patientTreatments = this.$store.getters["auth/isShowingPaymentTreatments"] || !paymentTreatment
      ? this.patient.treatments
      : this.patient.treatments.filter((patientTreatment) => (patientTreatment.treatment_id !== paymentTreatment.id));

    const records = this.$store.getters["auth/isShowingTreatmentFiles"]
      ? [
        ...patientTreatments.map(patientTreatment => ({ ...patientTreatment, itemType: "patientTreatment" })),
        ...this.patient.tasks.map(task => ({ ...task, itemType: "task" })),
        ...this.patient.task_recurrings.map(taskRecurring => ({ ...taskRecurring, itemType: "taskRecurring" })),
        ...this.patient.files.map(file => ({ ...file, itemType: "file" })),
        ...this.patient.lab_works.map(labWork => ({ ...labWork, itemType: "labWork" }))
      ]
      : [
        ...patientTreatments.map(patientTreatment => ({ ...patientTreatment, itemType: "patientTreatment" })),
        ...this.patient.tasks.map(task => ({ ...task, itemType: "task" })),
        ...this.patient.task_recurrings.map(taskRecurring => ({ ...taskRecurring, itemType: "taskRecurring" })),
        ...this.patient.lab_works.map(labWork => ({ ...labWork, itemType: "labWork" }))
      ];

    return records.sort((a, b) => {
      if (a.created_at < b.created_at) return 1;
      if (a.created_at > b.created_at) return -1;
      return 0;
    });
  }

  public rowClass(row: Task | TaskRecurring | PatientFile | PatientTreatment) {
    const itemIndex = this.patientTreatments.findIndex((paymentRow: Task | TaskRecurring | PatientFile | PatientTreatment) => (paymentRow === row));

    const classList: string[] = [];
    if (itemIndex > 0) {
      const previusRow = this.patientTreatments[itemIndex - 1];
      if (date(previusRow.created_at) !== date(row.created_at)) {
        classList.push("topBorder");
      }
    }

    return classList.join(" ");
  }

  public async onTreatmentUpdated(patientTreatment: PatientTreatment) {
    const index = this.patient.treatments.findIndex(treatments => treatments.id === patientTreatment.id);
    this.patient.treatments.splice(index, 1, patientTreatment);

    this.$toastr.s(this.$t("treatment_updated_toastr"));
  }

  public async onCreateLetter(patientTreatment: PatientTreatment) {
    this.$emit("createLetter", patientTreatment);
  }

  public async onTreatmentCreated(patientTreatment: PatientTreatment) {
    this.patient.treatments.unshift(patientTreatment);

    this.$toastr.s(this.$t("treatment_created_toastr"));
  }

  public fileNameUpdated(id: number, newName: string) {
    const file = this.patient.files.find(file => (file.id === id));
    if (file) {
      file.name = newName;
    }
    this.$emit("refreshPatientFiles");
  }

  public closeShowPatientFileDialog() {
    this.showPatientFile = null;
  }

  public fileDeleted() {
    this.$emit("reloadPatient");
  }

  public async onTreatmentDeleted(appointmentTreatmentId: number) {
    this.displayTreatmentFormDialog = false;
    this.editingTreatment = null;
    const index = this.patient.treatments.findIndex(treatments => treatments.id === appointmentTreatmentId);
    this.patient.treatments.splice(index, 1);

    this.$toastr.s(this.$t("treatment_deleted_toastr"));
  }

  public reloadPatient() {
    this.$emit("reloadPatient");
  }

  public openNewTreatmentDialog() {
    this.editingTreatment = null;
    this.displayTreatmentFormDialog = true;
  }

  public openNewTaskDialog() {
    this.editingTask = null;
    this.displayTaskFormDialog = true;
  }

  public tdStyle(item: PatientTreatment | Task | TaskRecurring | PatientFile) {
    if ("color" in item && item.color && item.color.toUpperCase() !== "#FFFFFF") {
      return "background-color: " + item.color;
    }

    return "";
  }

}
