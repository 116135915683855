import { AppHttpClient } from "@/core/http-client/http-client";
import { AxiosInstance, CancelTokenSource } from "axios";
import {
  PatientApiRouteParameters,
  PatientApiRoutes,
} from "@/modules/patient/constants/api-routes";
import { Patient } from "@/modules/patient/types/patient.type";
import { PaymentIncome } from "../types/payment.type";
import { ApiResponse } from "@/core/http-client/http-client.types";

interface ReceivePaymentParams {
  pinkasId: number;
  notes: string;
  description: string;
  paymentSplit: { [patientId: number]: number };
  incomes: PaymentIncome[];
}

interface HeshbonitDealParams {
  pinkasId: number;
  notes: string;
  description: string;
  totalDealAmount: number;
}
class PatientApi {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = AppHttpClient.instance;
  }

  async get(patientId: number): Promise<Patient> {
    try {
      const { data: patient }: ApiResponse<Patient> = await this.httpClient.get(
        PatientApiRoutes.getOne.replace(
          PatientApiRouteParameters.id,
          patientId.toString()
        )
      );

      // Set tasks patient
      patient.tasks.forEach((task) => {
        task.patient = patient;
        task.patient_id = patient.id;
      });

      return patient;
    } catch (e) {
      throw e;
    }
  }

  find(
    params,
    activeRequest: CancelTokenSource
  ): Promise<{ data: Patient[]; total: number }> {
    return this.httpClient
      .post(PatientApiRoutes.search, params, {
        cancelToken: activeRequest.token,
      })
      .then((response) => {
        return response.data;
      });
  }

  async receivePayment(
    patientId: number,
    params: ReceivePaymentParams
  ): Promise<void> {
    try {
      const response = await this.httpClient.post(
        PatientApiRoutes.receivePayment.replace(
          PatientApiRouteParameters.id,
          patientId.toString()
        ),
        params
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }
  async convertFutureHeshbonitMas(futureHeshbonitMasId: number): Promise<void> {
    try {
      const response = await this.httpClient.post(
        PatientApiRoutes.createFutureHeshbonitMas,
        {
          future_heshbonit_mas_id: futureHeshbonitMasId,
        }
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async createHeshbonitDeal(
    patientId: number,
    params: HeshbonitDealParams
  ): Promise<void> {
    try {
      const response = await this.httpClient.post(
        PatientApiRoutes.heshbonitDeal.replace(
          PatientApiRouteParameters.id,
          patientId.toString()
        ),
        params
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async sendEmailPaymentDocument(
    sendEmailTo: string,
    patientId: number,
    documentType: string,
    documentId: number
  ) {
    try {
      const response = await this.httpClient.post(
        PatientApiRoutes.sendEmailPaymentDocument,
        {
          sendEmailTo,
          patientId,
          documentType,
          documentId,
        }
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async refundPayment(
    pinkasId: number,
    patientId: number,
    amount: number,
    paymentType: "credit_card" | "cash" | "check" | "bank_transfer",
    description: string
  ): Promise<void> {
    try {
      const response = await this.httpClient.post(
        PatientApiRoutes.refundPayment.replace(
          PatientApiRouteParameters.id,
          patientId.toString()
        ),
        {
          amount,
          paymentType,
          pinkasId,
          description,
        }
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async cancelPayment(
    patientId: number,
    paymentId: number,
    cancelReason: string
  ): Promise<void> {
    try {
      const response = await this.httpClient.post(
        PatientApiRoutes.cancelPayment.replace(
          PatientApiRouteParameters.id,
          patientId.toString()
        ),
        {
          paymentId,
          cancelReason,
        }
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  quickSearch(
    query: string,
    includeArchived: boolean,
    activeRequest: CancelTokenSource
  ) {
    return this.httpClient.post(
      PatientApiRoutes.quickSearch,
      {
        query,
        includeArchived,
      },
      { cancelToken: activeRequest.token }
    );
  }

  delete(patientId) {
    return this.httpClient.delete(`/patient/${patientId}`);
  }

  async unarchive(id: number): Promise<Patient> {
    try {
      const response = await this.httpClient.post(
        PatientApiRoutes.unarchive.replace(
          PatientApiRouteParameters.id,
          id.toString()
        )
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async update(id: number, payload = {}): Promise<Patient> {
    try {
      const response = await this.httpClient.put(
        PatientApiRoutes.update.replace(
          PatientApiRouteParameters.id,
          id.toString()
        ),
        payload
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async create(payload = {}): Promise<Patient> {
    try {
      const response = await this.httpClient.post(
        PatientApiRoutes.create,
        payload
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async uploadProfilePicture(payload: FormData) {
    try {
      const response: ApiResponse<Patient> = await this.httpClient.post(
        PatientApiRoutes.uploadProfilePicture,
        payload
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async useProfilePicture(patientFileId: number) {
    try {
      const response: ApiResponse<Patient> = await this.httpClient.post(
        PatientApiRoutes.useProfilePicture,
        { patient_file_id: patientFileId }
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async removeProfilePicture(patientId: number) {
    try {
      const response: ApiResponse<Patient> = await this.httpClient.post(
        PatientApiRoutes.removeProfilePicture.replace(
          PatientApiRouteParameters.id,
          patientId.toString()
        )
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }
}

export default new PatientApi();
