

































































































































































import SmileMixin from "@/mixins/smile.mixin";
import { Component, Watch } from "vue-property-decorator";
import mixins from "vue-class-component";
import PaymentTranzilaApi from "../api/payment-tranzila.api";
import axios, { CancelTokenSource } from "axios";
import moment from "moment";
import { enableDatePickerAdjacentMonths } from "@/modules/appointment/helpers/date-picker-helpers";
import { PaymentTranzilaReportResults, PaymentTranzilaReportTransaction } from "../types/payment-tranzila.type";
import { time } from "@/filters/date.filter";
import { PATIENT_ROUTE_NAME } from "@/modules/patient/constants/route-config";
import { Getter } from "vuex-class";
import { Pinkas } from "../../pinkas/types/pinkas.type";

@Component({
  filters: {
    time,
  }
})
export default class TranzilaReportPage extends mixins(SmileMixin) {
  @Getter("auth/pinkasList") pinkasList: Pinkas[];
  @Getter("auth/isRtl") isRtl: boolean;
  @Getter("auth/userDefaultPinkasId") userDefaultPinkasId: number;
  public pinkasId: number = null;
  public date: string = null;
  public displayDatePicker = false;
  public isLoading = false;
  public reportResults: PaymentTranzilaReportResults = null;
  private activeRequest: CancelTokenSource;

  public mounted() {
    this.applyFromQueryStringFilters();
    if (!this.date) {
      this.date = moment().format("YYYY-MM-DD");
    }
    if (!this.pinkasId) {
      this.pinkasId = this.userDefaultPinkasId ? this.userDefaultPinkasId : this.tranzilaPinkasList[0].id;
    }
    this.reload();
  }

  get tranzilaPinkasList() {
    return this.pinkasList.filter(({ is_tranzila_enabled }) => (is_tranzila_enabled));
  }

  @Watch("date")
  @Watch("pinkasId")
  onFiltersChanged() {
    this.reload();
  }

  get headers() {
    return [
      { text: this.$t("headers.created_at"), value: "created_at", sortable: false },
      { text: this.$t("headers.transaction_id"), value: "transaction_id", sortable: false },
      { text: this.$t("headers.transaction_type_weaver"), value: "transaction_type_weaver", sortable: false },
      { text: this.$t("headers.card_number"), value: "card_number", sortable: false },
      { text: this.$t("headers.card_type"), value: "card_type", sortable: false },
      { text: this.$t("headers.brand"), value: "brand", sortable: false },
      { text: this.$t("headers.original_amount"), value: "amount", sortable: false },
      { text: this.$t("headers.credit_terms"), value: "credit_terms", sortable: false },
      { text: this.$t("headers.transaction_status"), value: "transaction_status", sortable: false },
    ];
  }

  get patientRouteName() {
    return PATIENT_ROUTE_NAME;
  }

  public updated() {
    this.enableAdjacentMonths();
  }

  public enableAdjacentMonths() {
    setTimeout(() => enableDatePickerAdjacentMonths(), 50);
  }

  get dateFormatted() {
    return moment(this.date).format("DD/MM/YYYY");
  }

  public async reload() {
    this.updateQueryStringFilters();
    this.isLoading = true;
    this.reportResults = null;

    if (this.activeRequest) {
      this.activeRequest.cancel();
    }
    this.activeRequest = axios.CancelToken.source();

    try {
      this.reportResults = await PaymentTranzilaApi.getTransactions(this.pinkasId, this.date, this.date, this.activeRequest);

      this.isLoading = false;
    } catch (err) {

      if (axios.isCancel(err)) {
        return;
      }

      this.isLoading = false;
      this.$toastr.e(this.$t("temporary_error"));
    }
  }

  public async cancelTranscationClicked(transaction: PaymentTranzilaReportTransaction) {
    const res = await this.$confirm(
      this.$t("cancel_transaction_confirm_message").toString(),
      {
        buttonTrueColor: "red",
        buttonTrueText: this.$t("cancel_transaction_ok_btn").toString(),
        buttonFalseText: this.$t("cancel_transaction_cancel_btn").toString(),
      });

    if (!res) {
      return;
    }

    try {
      await PaymentTranzilaApi.cancelTransaction(this.pinkasId, transaction.index, transaction.authorization_number);

      this.isLoading = false;
      this.$toastr.s(this.$t("transaction_canceled_toastr"));
    } catch (err) {

      if (axios.isCancel(err)) {
        return;
      }

      this.isLoading = false;
      this.$toastr.e(this.$t("cancel_temporary_error"));
    } finally {
      this.reload();
    }
  }

  public applyFromQueryStringFilters() {
    const queryDate = this.$route.query.date && this.$route.query.date.toString();
    const queryBusiness = parseInt(this.$route.query.business && this.$route.query.business.toString());

    if (queryDate) {
      this.date = queryDate;
    }
    if (queryBusiness) {
      this.pinkasId = queryBusiness;
    }
  }

  public updateQueryStringFilters() {
    const query = {
      date: this.date,
      business: this.pinkasId ? this.pinkasId.toString() : undefined,
    };

    this.$router.replace({ ...this.$router.currentRoute, query });
  }

  get totalReturnTransactionsAmount() {
    return this.reportResults.transactions
      .filter(({ processor_response_code, tranmode }) => (processor_response_code === "000" && tranmode[0] === "C"))
      .map(({ amount }) => (parseFloat(amount) / 100))
      .reduce((a, b) => (a + b), 0);
  }

  get totalReturnTransactionsCount() {
    return this.reportResults.transactions
      .filter(({ processor_response_code, tranmode }) => (processor_response_code === "000" && tranmode[0] === "C"))
      .length;
  }

  get totalApprovedTransactionsAmount() {
    return this.reportResults.transactions
      .filter(({ processor_response_code, tranmode }) => (processor_response_code === "000" && tranmode === "A"))
      .map(({ amount }) => (parseFloat(amount) / 100))
      .reduce((a, b) => (a + b), 0);
  }

  get totalApprovedTransactionsCount() {
    return this.reportResults.transactions
      .filter(({ processor_response_code, tranmode }) => (processor_response_code === "000" && tranmode === "A"))
      .length;
  }

  get totalRejectedTransactionsAmount() {
    return this.reportResults.transactions
      .filter(({ processor_response_code }) => (processor_response_code !== "000"))
      .map(({ amount }) => (parseFloat(amount) / 100))
      .reduce((a, b) => (a + b), 0);
  }

  get totalRejectedTransactionsCount() {
    return this.reportResults.transactions
      .filter(({ processor_response_code }) => (processor_response_code !== "000"))
      .length;
  }
}
