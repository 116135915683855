






















































































































































































import axios, { CancelTokenSource } from "axios";
import { Component, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { REPORTS_ROUTE_NAME } from "../constants/route-config";
import { date, time, age } from "@/filters/date.filter";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import { PATIENT_ROUTE_NAME } from "@/modules/patient/constants/route-config";
import moment from "moment";
import { Pinkas } from "@/modules/pinkas/types/pinkas.type";
import { enableDatePickerAdjacentMonths } from "@/modules/appointment/helpers/date-picker-helpers";
import ReportApi from "../api/report.api";
import { ReportAllIncomesResults } from "../types/report.type";
import ShowPaymentDocumentFileDialog from "@/modules/patient/components/PatientPayment/ShowPaymentDocumentFileDialog.vue";
import { HeshbonitMasKabala, Kabala, PaymentIncome } from "@/modules/patient/types/payment.type";

@Component({
  components: {
    ShowPaymentDocumentFileDialog,
  },
  filters: {
    date,
    time,
    age,
  }
})
export default class ReportAllIncomesPage extends mixins(SmileMixin) {
  @Getter("auth/isRtl") isRtl: boolean;
  @Getter("auth/pinkasList") pinkasList: Pinkas[];
  @Getter("auth/isShowingExportPdfInvoicesButton") isShowingExportPdfInvoicesButton: boolean;
  @Getter("auth/userDefaultPinkasId") userDefaultPinkasId: number;
  private activeRequest: CancelTokenSource = null;
  public displayDocument: Kabala | HeshbonitMasKabala = null;
  public reportResults: ReportAllIncomesResults = null;
  public fromDate: string = null;
  public toDate: string = null;
  public pinkasId: number = null;
  public displayFromDatePicker = false;
  public displayToDatePicker = false;
  public dateMenuOpen = false;
  public isLoading = false;
  public isExportExcelLoading = false;
  public isExportPdfLoading = false;

  get reportsPageRouteName() {
    return REPORTS_ROUTE_NAME;
  }

  get isMobile() {
    return this.$vuetify.breakpoint.xsOnly;
  }

  @Watch("fromDate")
  @Watch("toDate")
  @Watch("pinkasId")
  onFiltersChanged() {
    this.reload();
  }

  get headers() {
    return [
      { text: this.$t("headers.document_number"), value: "document_number", sortable: false },
      { text: this.$t("headers.document_type"), value: "document_type", sortable: false },
      { text: this.$t("headers.document_created_at"), value: "created_at", sortable: false },
      { text: this.$t("headers.value_date"), value: "value_date", sortable: false },
      { text: this.$t("headers.document_details"), value: "document_details", sortable: false },
      { text: this.$t("headers.patient_name"), value: "patient_name", sortable: false },
      { text: this.$t("headers.amount_without_maam"), value: "amount_without_maam", sortable: false },
      { text: this.$t("headers.maam_amount"), value: "maam_amount", sortable: false },
      { text: this.$t("headers.amount"), value: "amount", sortable: false },
    ];
  }

  public async onExportExcelClicked() {
    this.isExportExcelLoading = true;

    try {
      const url = await ReportApi.getAllInvoicesUrl(this.pinkasId, this.fromDate, this.toDate, "excel");
      this.isExportExcelLoading = false;

      const res = await this.$confirm(
        this.$t("export_confirm_message").toString(),
        {
          buttonTrueColor: "cyan darken-1",
          buttonTrueText: this.$t("export_ok_btn").toString(),
          buttonFalseText: "",
        });

      if (!res) {
        return;
      }

      this.downloadFile(url, this.$t("reports.all-incomes-report.title") + ".xlsx");
    } catch (err) {
      this.$toastr.e(this.$t("temporary_error"));
      this.isExportExcelLoading = false;
    }
  }

  public async onExportPdfClicked() {
    this.isExportPdfLoading = true;

    try {
      const url = await ReportApi.getAllInvoicesUrl(this.pinkasId, this.fromDate, this.toDate, "pdf");
      this.isExportPdfLoading = false;

      const res = await this.$confirm(
        this.$t("export_confirm_message").toString(),
        {
          buttonTrueColor: "cyan darken-1",
          buttonTrueText: this.$t("export_ok_btn").toString(),
          buttonFalseText: "",
        });

      if (!res) {
        return;
      }

      this.downloadFile(url, this.$t("reports.all-incomes-report.title") + ".xlsx");
    } catch (err) {
      this.$toastr.e(this.$t("temporary_error"));
      this.isExportPdfLoading = false;
    }
  }

  public getIncomeDate(income: PaymentIncome) {
    if (income.credit_card_info) {
      return income.credit_card_info.date;
    }
    if (income.bank_transfer_info) {
      return income.bank_transfer_info.date;
    }
    if (income.check_info) {
      return income.check_info.date;
    }
    return null;
  }

  public async reload() {
    this.updateQueryStringFilters();
    this.isLoading = true;
    this.reportResults = null;

    if (this.activeRequest) {
      this.activeRequest.cancel();
    }
    this.activeRequest = axios.CancelToken.source();

    try {
      this.reportResults = await ReportApi
        .getAllIncomesReport(this.pinkasId, this.fromDate, this.toDate, this.activeRequest);

      this.isLoading = false;
    } catch (err) {

      if (axios.isCancel(err)) {
        return;
      }

      this.isLoading = false;
      this.$toastr.e(this.$t("temporary_error"));
    }
  }

  public mounted() {
    this.applyFromQueryStringFilters();
    if (!this.fromDate) {
      this.fromDate = moment().startOf("month").format("YYYY-MM-DD");
    }
    if (!this.toDate) {
      this.toDate = moment().format("YYYY-MM-DD");
    }
    if (!this.pinkasId) {
      this.pinkasId = this.userDefaultPinkasId ? this.userDefaultPinkasId : this.pinkasList[0].id;
    }
    this.reload();
  }

  public updated() {
    this.enableAdjacentMonths();
  }

  public enableAdjacentMonths() {
    setTimeout(() => enableDatePickerAdjacentMonths(), 50);
  }

  get fromDateFormatted() {
    return moment(this.fromDate).format("DD/MM/YYYY");
  }

  get toDateFormatted() {
    return moment(this.toDate).format("DD/MM/YYYY");
  }

  get patientRouteName() {
    return PATIENT_ROUTE_NAME;
  }

  public applyFromQueryStringFilters() {
    const queryFromDate = this.$route.query.from && this.$route.query.from.toString();
    const queryToDate = this.$route.query.to && this.$route.query.to.toString();
    const queryBusiness = parseInt(this.$route.query.business && this.$route.query.business.toString());

    if (queryFromDate) {
      this.fromDate = queryFromDate;
    }
    if (queryToDate) {
      this.toDate = queryToDate;
    }
    if (queryBusiness) {
      this.pinkasId = queryBusiness;
    }
  }

  public updateQueryStringFilters() {
    const query = {
      from: this.fromDate,
      to: this.toDate,
      business: this.pinkasId ? this.pinkasId.toString() : undefined,
    };

    this.$router.replace({ ...this.$router.currentRoute, query });
  }

}
