






















































import { Component } from "vue-property-decorator";
import { Getter } from "vuex-class";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import moment from "moment";
import { enableDatePickerAdjacentMonths } from "@/modules/appointment/helpers/date-picker-helpers";
import ReportApi from "../api/report.api";
import { Pinkas } from "@/modules/pinkas/types/pinkas.type";

@Component({
})
export default class ReportHashavshevetDialog extends mixins(SmileMixin) {
  @Getter("auth/isRtl") isRtl: boolean;
  @Getter("auth/pinkasList") pinkasList: Pinkas[];
  @Getter("auth/userDefaultPinkasId") userDefaultPinkasId: number;
  public isExportLoading = false;
  public pinkasId: number = null;
  public fromDate: string = null;
  public toDate: string = null;
  public displayFromDatePicker = false;
  public displayToDatePicker = false;

  public closeDialog() {
    this.$emit("close");
  }

  public updated() {
    this.enableAdjacentMonths();
  }

  public enableAdjacentMonths() {
    setTimeout(() => enableDatePickerAdjacentMonths(), 50);
  }

  get fromDateFormatted() {
    return moment(this.fromDate).format("DD/MM/YYYY");
  }

  get toDateFormatted() {
    return moment(this.toDate).format("DD/MM/YYYY");
  }

  public mounted() {
    this.fromDate = moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD");
    this.toDate = moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD");
    this.pinkasId = this.userDefaultPinkasId ? this.userDefaultPinkasId : this.pinkasList[0].id;
  }

  public async onExportClicked() {

    this.isExportLoading = true;

    try {
      const url = await ReportApi.getExportUrlHashavshevet(this.pinkasId, this.fromDate, this.toDate);
      this.isExportLoading = false;

      const res = await this.$confirm(
        this.$t("export_confirm_message").toString(),
        {
          buttonTrueColor: "cyan darken-1",
          buttonTrueText: this.$t("export_ok_btn").toString(),
          buttonFalseText: "",
        });

      if (!res) {
        return;
      }

      this.downloadFile(url, "OPENFRMT.zip");
      this.closeDialog();
    } catch (err) {
      this.$toastr.e(this.$t("temporary_error"));
      this.isExportLoading = false;
    }
  }

}
