export enum PatientApiRoutes {
  getOne = "/patient/{id}",
  search = "/patient/search",
  create = "/patient",
  update = "/patient/{id}",
  delete = "/patient/{id}",
  unarchive = "/patient/{id}/unarchive",
  receivePayment = "/patient/{id}/payment",
  heshbonitDeal = "/patient/{id}/heshbonit-deal",
  cancelPayment = "/patient/{id}/cancel-payment",
  refundPayment = "/patient/{id}/refund-payment",
  createFutureHeshbonitMas = "/payment/convert-future-heshbonit-mas",
  quickSearch = "/patient/quick-search",
  sendEmailPaymentDocument = "/patient/send-email-payment-document",
  uploadProfilePicture = "/patient/upload-profile-picture",
  useProfilePicture = "/patient/use-profile-picture",
  removeProfilePicture = "/patient/{id}/remove-profile-picture",
}

export enum PatientApiRouteParameters {
  id = "{id}",
}

export enum PatientNoteApiRoutes {
  create = "/patient-note",
  update = "/patient-note/{id}",
  delete = "/patient-note/{id}",
}

export enum PatientNoteApiRouteParameters {
  id = "{id}",
}

export enum PatientLetterApiRoutes {
  create = "/patient-letter",
  createDrugPrescription = "/patient/drug-prescription",
  createExportPatient = "/patient/export-letter",
  sendEmailFile = "/patient/send-email-file",
  createDynamicLetter = "/patient/dynamic-letter",
}

export enum PatientTreatmentApiRoutes {
  create = "/patient-treatment",
  update = "/patient-treatment/{id}",
  find = "/patient-treatment",
  export = "/patient-treatments-export",
  delete = "/patient-treatment/{id}",
}

export enum PatientTreatmentGroupByApiRoutes {
  getTreatmentGroupByTreatmentName = "/patient-treatments-group-by-treatment",
}

export enum PatientTreatmentApiRouteParameters {
  id = "{id}",
}

export enum FamilyApiRoutes {
  markAsFamily = "/family/mark-as-family",
  removeFamily = "/family/remove-family",
}
